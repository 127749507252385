import { register, type SwiperContainer } from "swiper/element/bundle";
import classNames from "classnames";
import { useEffect, useLayoutEffect, useRef, useState } from "preact/hooks";
import useModal from "../../../hooks/useModal.ts";

import Modal from "@components/Modal";

import s from "./CardholderModal.module.scss";
import imgOpened from "../../../../public/img/cardholders/cardholder-opened.png";
import imgClosed from "../../../../public/img/cardholders/cardholder-closed.png";
import imgSide from "../../../../public/img/cardholders/cardholder-side.png";
import imgOpenedSmall from "../../../../public/img/cardholders/cardholder-opened-small.png";
import imgClosedSmall from "../../../../public/img/cardholders/cardholder-closed-small.png";
import imgSideSmall from "../../../../public/img/cardholders/cardholder-side-small.png";
import buttonImg from "../../../../public/img/cardholders/cardholder.png";

const slides = [
  { key: "closed", src: imgClosed, smallSrc: imgClosedSmall },
  { key: "side", src: imgSide, smallSrc: imgSideSmall },
  { key: "opened", src: imgOpened, smallSrc: imgOpenedSmall },
];

const swiperParams = {
  injectStyles: [
    `
    .swiper-button-next, .swiper-button-prev {
        color: white;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: #313131;
        svg {
          width: 6px;
        }
    }
    .swiper-button-disabled {
        background-color: #1F1F1F;
        opacity: 1 !important;
    }
  `,
  ],
  slidesPerView: "auto",
  navigation: true,
  cssMode: true,
  breakpoints: {
    768: {
      cssMode: false,
    },
  },
  centeredSlides: true,
  speed: 800,
  watchSlidesProgress: true,
};

export const CardholderModal = () => {
  const { isShowing, hide, show } = useModal("show-cardholder", "");
  const swiperRef = useRef<SwiperContainer | null>(null);
  const [activeIndex, setActiveIndex] = useState(0);

  const onModalReady = () => {
    Object.assign(swiperRef.current!, swiperParams);
    swiperRef.current!.initialize();
    swiperRef.current?.swiper.on("slideChange", (e) =>
      setActiveIndex(e.activeIndex),
    );
  };
  
  useEffect(() => {
    register();
  }, []);

  const clickHandler = (index: number) => {
    setActiveIndex(index);
    swiperRef.current?.swiper?.slideTo(index);
  };

  return (
    <>
      <button onClick={show} type="button" className={s.showButton}>
        <img src={buttonImg.src} width="64" height="64" alt="cardholder" />
      </button>
      <Modal isShowing={isShowing} hide={hide} onReady={onModalReady} isCentered>
        <div className={s.modalContent}>
          <swiper-container
            init="false"
            id="cardholder-view"
            ref={swiperRef}
            className={s.swiperContainer}
          >
            {slides.map((view) => (
              <swiper-slide key={view.key} className={s.slide}>
                <img
                  src={view.src.src}
                  alt={`${view.key} view`}
                  loading="lazy"
                  className={s.image}
                />
              </swiper-slide>
            ))}
          </swiper-container>
          <div className={s.slideButtonsContainer}>
            {slides.map((view, index) => (
              <button
                className={classNames(s.slideButton, {
                  [s.slideButtonActive]: index === activeIndex,
                })}
                type="button"
                onClick={() => clickHandler(index)}
              >
                <img
                  src={view.smallSrc.src}
                  alt={`${view.key} view`}
                  loading="lazy"
                  className={s.slideButtonImage}
                />
              </button>
            ))}
          </div>
        </div>
        <button className={s.closeButton} onClick={hide} />
      </Modal>
    </>
  );
};
